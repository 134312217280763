.testimonials{
    display: flex;
    padding: 0 2rem;
    gap: 1rem;
}
.left-t{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
    color: white;
}
.left-t>:nth-child(1){
    text-transform: uppercase;
    color: var(--orange);
    font-size: 1rem;
    font-weight: bold;
}
.left-t>:nth-child(2){
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
}
.left-t>:nth-child(3){
    text-transform: uppercase;
    font-size: 3rem;
    color: white;
    font-weight: bold;
}
.left-t>:nth-child(4){
    letter-spacing: 2px;
    line-height: 40px;
    text-align: justify;
}
.left-t>div :nth-child(1){
    color: var(--orange);
}


.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    width: 17rem;
    height: 20rem;
    position: absolute;
    right: 8rem;
    top: 2rem;
}

.right-t>:nth-child(1){
    width: 17rem;
    height: 20rem;
    border: 2px solid var(--orange);
    position: absolute;
    right: 9rem;
    top: 0.9rem;
}
.right-t>:nth-child(2){
    width: 17rem;
    height: 19rem;
    position: absolute;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}

.arrows{
    display: flex;
    gap: 1rem;
    align-items: center;
    position: absolute;
    left: 3rem;
    bottom: 1rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}


@media screen and (max-width: 768px){
    .testimonials{
        flex-direction: column;
    }

    .left-t>:nth-child(2),
    .left-t>:nth-child(3){
        font-size: xx-large;
    }

    .right-t{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
    }
    .right-t> :nth-child(1),
    .right-t> :nth-child(2){
        display: none;
    }

    .right-t>img{
        position: relative;
        top: 0;
        right: 0;
        align-self: center;
    }
    .arrows{
        position: relative;
        align-self: center;
        display: block;
        bottom: 0;
        right: 0;
    }
}