.reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}
.left-r{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r :nth-child(1){
    width: 12rem;
    height: 28rem;
    grid-row: 1/3;
}
.left-r :nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-r :nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-r :nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
}

.right-r{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    color: white;
}

.right-r > :nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.right-r :nth-child(2){
    font-size: 3rem;
    font-weight: bold;
}
.right-r>div{
    display: flex;
    gap: 1rem;
}
.right-r > div >img{
    width: 2rem;
}
.right-r > :nth-child(3) span{
    font-size: 1rem;
}
.right-r > :nth-child(4) span{
    font-size: 1rem;
}
.right-r > :nth-child(5) span{
    font-size: 1rem;
}
.right-r > :nth-child(6) span{
    font-size: 1rem;
}
.right-r :nth-child(7){
    color: var(--gray);
}
.right-r .brands>img{
    width: 2.5rem;
}

@media screen and (max-width:1000px) {
    .left-r{
        transform: scale(0.8);
    }
    .reasons {
        flex-direction: column;
        align-items: center;
    }
}


@media screen and (max-width:768px) {
    .reasons{
        flex-direction: column-reverse;
    }
    .left-r{
        transform: scale(0.6);
        margin: -7rem;
    }
    .right-r>:nth-child(2) {
        flex-direction: column;
    }
}