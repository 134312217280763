
.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.footer>:nth-child(1){
    position: absolute;
    background-color: #ff7300;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
}
.footer>:nth-child(2){
    position: absolute;
    background-color: red;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
}

.footer>hr{
    border: 1px solid var(--lightgray);
    width: 100%;
}

.footer-container{
    display: flex;
    height: 20rem;
    gap: 4rem;
    padding: 1rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-media{
    display: flex;
    gap: 4rem;
}

.social-media>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}