.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    justify-content: center;
    gap: 5rem;
    font-size: 3rem;
    color: white;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
}
.programs-cards{
    display: flex;
    gap: 1rem;
}
.card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    background-color: rgb(128, 128, 128);
    color: white;
    flex: 1;
    gap: 1rem;
    cursor: pointer;
}
.card:hover{
    background: var(--planCard);
}
.card >:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.card> :nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
    color: white;
}
.card > :nth-child(2){
    font-weight: bold;
    color: white;
}
.join-now{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.join-now>img{
    width: 1rem;
}

@media screen and (max-width: 1000px) {
    .programs-header {
        gap: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .programs{
        margin-top: 2rem;
    }
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        text-align: center;
        align-items: center;
    }
    .programs-cards{
        flex-direction: column;
        align-items: center;
    }
}